.checkin-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 2px solid #ccc;
    border-radius: 10px;
    max-width: 500px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  
  .checkin-label {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .checkin-input {
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .checkin-button {
    background-color: #008CBA;
    color: white;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .checkin-button-danger {
    background-color: #ec1818;
    color: white;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .checkin-button:hover {
    background-color: #006C9D;
  }

  .proggresbarComplet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: var(--bs-progress-bar-color);
    text-align: center;
    white-space: nowrap;
    background-color: red;
    transition: var(--bs-progress-bar-transition);
  }

  .form-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    font-size: 15px;
  }

  .form-group label{
    margin-right: 10px;
    margin-left: 20px;
  }

  .form-group input[type="radio"] {
    margin: 10px 0;
  }
#conf h1 {
    font-size: 1.5rem;
  }

  #conf {
    margin-left: 2rem;
    margin-top: 2rem;
    font-size: 15px;
  }
  
  /* Estilo para las filas en negritas */
  #conf li {
    margin-bottom: 0.5rem;
  }
  
  #conf li > span {
    font-weight: bold;
  }
  
  /* Estilo para los mensajes de fallo en rojo */
  #conf li > span.red-text {
    color: red;
  }

  #conf .success-message {
    color: green;
  }
  
  /* Estilo para la tabla */
  #conf table {
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-color: #f9f9f9;
  }
  
  #conf table td {
    padding: 0.5rem;
    border: 1px solid #ccc;
    align-items: center;
    padding: 20px;
    border: 2px solid #ccc;
    border-radius: 20px;
  }
  
  /* Estilo para el botón "Actualizar" */
  #conf button {
    background-color: #007bff;
    color: #fff;
    padding: 0.5rem 1rem;
    border: none;
    cursor: pointer;
    margin-bottom: 2rem;
  }
  
  #conf button:hover {
    background-color: #0056b3;
  }

  #conf .button-container {
    display: flex;
    justify-content: flex-end; /* Esto alinea el contenido al extremo derecho */
    margin-right: 2rem; /* Ajusta el margen superior según sea necesario */
  }
  
  #conf #reg {
    height: 400px;
    max-height: 400px; /* Ajusta la altura máxima según tus preferencias */
    overflow-y: scroll; /* Agrega una barra de desplazamiento vertical cuando los mensajes superen la altura máxima */
    border: 1px solid;
    padding: 1rem;
    margin-right: 2rem;
    border: 2px solid #ccc;
    border-radius: 20px;
    background-color: #f9f9f9;
    margin-bottom: 2rem;;
}  

